import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportFilter from './components/ReportFilter';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import MapHeatMap from '../map/heatmap/MapHeatMap';
import MapView from '../map/core/MapView';
import { jsonToGeoJson } from '../common/util/converter';

const useStyles = makeStyles(() => ({
  mapContainer: {
    flexGrow: 1,
  },
}));

const HeatmapReportPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(null);
  const classes = useStyles();

  const handleSubmit = useCatch(async ({ deviceIds, from, to, type }) => {
    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    if (type === 'export') {
      window.location.assign(`/api/reports/route/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reports/route/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/route?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          const res = await response.json();
          if (res) {
            const resGeoJson = jsonToGeoJson(res);
            setItems(resGeoJson);
          }
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'heatmap']}>
      {loading ? <Skeleton variant="text" style={{ margin: 30 }} /> : <ReportFilter handleSubmit={handleSubmit} showOnly multiDevice />}
      <div className={classes.mapContainer}>
        <MapView>
          {items && <MapHeatMap data={items} enabled />}
        </MapView>
      </div>
    </PageLayout>
  );
};

export default HeatmapReportPage;
