import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const BillingAddressPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () => item;

  return (
    <EditItemView
      endpoint="billingaddresses"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['billingInfo']}
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.cf || ''}
              onChange={(event) => setItem({ ...item, cf: event.target.value })}
              label={t('cf')}
            />
            <TextField
              value={item.vat || ''}
              onChange={(event) => setItem({ ...item, vat: event.target.value })}
              label={t('vat')}
            />
            <TextField
              value={item.businessname || ''}
              onChange={(event) => setItem({ ...item, businessname: event.target.value })}
              label={t('businessname')}
            />
            <TextField
              value={item.country || ''}
              onChange={(event) => setItem({ ...item, country: event.target.value })}
              label={t('country')}
            />
            <TextField
              value={item.province || ''}
              onChange={(event) => setItem({ ...item, province: event.target.value })}
              label={t('province')}
            />
            <TextField
              value={item.city || ''}
              onChange={(event) => setItem({ ...item, city: event.target.value })}
              label={t('city')}
            />
            <TextField
              value={item.address || ''}
              onChange={(event) => setItem({ ...item, address: event.target.value })}
              label={t('address')}
            />
            <TextField
              value={item.cap || ''}
              onChange={(event) => setItem({ ...item, cap: event.target.value })}
              label={t('cap')}
            />
            <TextField
              value={item.sdi || ''}
              onChange={(event) => setItem({ ...item, sdi: event.target.value })}
              label={t('sdi')}
            />
            <TextField
              value={item.pec || ''}
              onChange={(event) => setItem({ ...item, pec: event.target.value })}
              label={t('pec')}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default BillingAddressPage;
