import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ReportFilter from './components/ReportFilter';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import { formatTime } from '../common/util/formatter';
import { generateRandomColor } from '../common/util/converter';
import { useTranslation } from '../common/components/LocalizationProvider';

const StationsReportPage = () => {
  const [stationObjects, setStationObjects] = useState([]);
  const [startStations, setStartStations] = useState();
  const [stopStations, setStopStations] = useState();
  const t = useTranslation();
  const geofences = useSelector((state) => state.geofences.items);

  async function initStationObject() {
    const stationSupport = [];
    Object.entries(geofences).forEach((geofence) => {
      if ('Station' in geofence[1].attributes) {
        if (geofence[1].attributes.Station === true) {
          stationSupport.push({
            id: geofence[1].id,
            name: geofence[1].name,
            starts: 0,
            stops: 0,
            totalInterations: 0,
          });
        }
      }
    });
    return stationSupport;
  }

  const handleSubmit = useCatch(async ({ from, to, userId }) => {
    const query = new URLSearchParams({ from, to, userId });
    try {
      const response = await fetch(`/api/reportsext/rents?${query.toString()}`);
      if (response.ok) {
        const rides = await response.json();
        const stationObjectsSupport = await initStationObject();
        const startSupport = [];
        const stopSupport = [];
        for (let i = 0; i < 24; i += 1) {
          const start = {
            name: i < 10 ? `0${i}` : `${i}`,
          };
          const stop = {
            name: i < 10 ? `0${i}` : `${i}`,
          };
          stationObjectsSupport.forEach((stationObject) => {
            stop[stationObject.name] = 0;
            start[stationObject.name] = 0;
          });
          startSupport.push(start);
          stopSupport.push(stop);
        }
        rides.forEach((ride) => {
          const timeStart = formatTime(ride.dateStart, 'time').split(':');
          const timeStop = formatTime(ride.dateEnd, 'time').split(':');
          const indexStart = stationObjectsSupport.findIndex((stationObject) => stationObject.id === ride.startGeofences);
          const indexStop = stationObjectsSupport.findIndex((stationObject) => stationObject.id === ride.stopGeofences);
          const startObject = stationObjectsSupport.find((stationObject) => stationObject.id === ride.startGeofences);
          const stopObject = stationObjectsSupport.find((stationObject) => stationObject.id === ride.stopGeofences);
          if (indexStop >= 0) {
            stationObjectsSupport[indexStop].stops += ride.deviceIds.length;
            stationObjectsSupport[indexStop].totalInterations += ride.deviceIds.length;
            stopSupport[parseInt(timeStop[0], 10)][stopObject.name] += ride.deviceIds.length;
          }
          if (indexStart >= 0) {
            stationObjectsSupport[indexStart].starts += ride.deviceIds.length;
            stationObjectsSupport[indexStart].totalInterations += ride.deviceIds.length;
            startSupport[parseInt(timeStart[0], 10)][startObject.name] += ride.deviceIds.length;
          }
        });
        setStartStations(startSupport);
        setStopStations(stopSupport);
        setStationObjects(stationObjectsSupport);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  });

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'stations']}>
      <ReportFilter handleSubmit={handleSubmit} includeManagers ignoreDevice showOnly />
      {startStations && (
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
          <Grid item xs={12} md={12} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <BarChart
                data={stationObjects}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="starts" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                <Bar dataKey="stops" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <BarChart
                data={stationObjects}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="starts" fill="#8884d8" name="Start" activeBar={<Rectangle fill="green" stroke="blue" />} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  data={stationObjects}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="starts"
                >
                  {stationObjects.map((entry) => (
                    <Cell key={entry.name} fill={`#${generateRandomColor()}`} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <BarChart
                data={stationObjects}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="stops" fill="#8884d8" name="Stop" activeBar={<Rectangle fill="green" stroke="blue" />} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  data={stationObjects}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="stops"
                >
                  {stationObjects.map((entry) => (
                    <Cell key={entry.name} fill={`#${generateRandomColor()}`} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <BarChart
                data={stationObjects}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalInterations" fill="#8884d8" name="Interazioni totali" activeBar={<Rectangle fill="green" stroke="blue" />} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  data={stationObjects}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="totalInterations"
                >
                  {stationObjects.map((entry) => (
                    <Cell key={entry.name} fill={`#${generateRandomColor()}`} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0}>
            <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
              <span style={{ margin: 'auto' }}>{t('startForHours')}</span>
            </div>
            <ResponsiveContainer height={400}>
              <BarChart
                data={startStations}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {Object.keys(startStations[0])
                  .filter((propName) => propName !== 'name')
                  .map(((station, index) => (
                    <Bar key={index} dataKey={station} stackId={startStations[0].name} fill={`#${generateRandomColor()}`} />
                  )))}
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0} style={{ overflow: 'hidden' }}>
            <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
              <span style={{ margin: 'auto' }}>{t('stopForHours')}</span>
            </div>
            <ResponsiveContainer height={400}>
              <BarChart
                data={stopStations}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {Object.keys(stopStations[0])
                  .filter((propName) => propName !== 'name')
                  .map(((station, index) => (
                    <Bar key={index} dataKey={station} stackId={stopStations[0].name} fill={`#${generateRandomColor()}`} />
                  )))}
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default StationsReportPage;
