import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMenu from './components/PoiMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const TagPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () => item && item.name && item.icon;

  return (
    <EditItemView
      endpoint="tags"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<PoiMenu />}
      breadcrumbs={['managePoi']}
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.name || ''}
              onChange={(event) => setItem({ ...item, name: event.target.value })}
              label={t('sharedName')}
            />
            <TextField
              value={item.icon || ''}
              onChange={(event) => setItem({ ...item, icon: event.target.value })}
              label="Icon"
            />
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default TagPage;
