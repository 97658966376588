import React, { useEffect, useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import CollectionActions from '../settings/components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useSuperManager } from '../common/util/permissions';
import useReportStyles from '../reports/common/useReportStyles';
import ReportFilter from '../reports/components/ReportFilter';
import CollectionFab from '../settings/components/CollectionFab';
import { useCatch } from '../reactHelper';
import RatesMenu from './components/RatesMenu';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  used: {
    color: '#FF0000',
  },
  notUsed: {
    color: theme.palette.primary.main,
  },
}));

const VouchersPage = () => {
  const classes = useStyles();
  const classesReport = useReportStyles();
  const t = useTranslation();
  const from = '2019-08-24T14:15:22Z';
  const to = '2044-08-24T14:15:22Z';

  const superManager = useSuperManager();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState();

  useEffect(() => {}, [timestamp]);

  const handleSubmit = useCatch(async ({ userId, type }) => {
    const query = new URLSearchParams({ from, to, userId });
    if (type === 'export') {
      window.location.assign(`/api/reportsext/vouchers/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reportsext/vouchers/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reportsext/vouchers?${query.toString()}`);
        if (response.ok) {
          setItems(await response.json());
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <PageLayout menu={<RatesMenu />} breadcrumbs={['vouchers']}>
      <div className={classesReport.containerMain}>
        <div className={classesReport.header}>
          <ReportFilter handleSubmit={handleSubmit} ignoreDevice includeManagers ignorePeriod />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnAction} />
              <TableCell>{t('code')}</TableCell>
              <TableCell>{t('useDate')}</TableCell>
              <TableCell>{t('dateCreation')}</TableCell>
              <TableCell>{t('userExpirationTime')}</TableCell>
              <TableCell>{t('stateValue')}</TableCell>
              <TableCell>{t('um')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.columnAction} padding="none">
                  {superManager &&
                    (
                    <CollectionActions
                      itemId={item.voucherId}
                      endpoint="vouchers"
                      setTimestamp={setTimestamp}
                    />
                    )}
                </TableCell>
                <TableCell>{item.voucher}</TableCell>
                {item.used ?
                  <TableCell className={classes.used}>{formatTime(item.used, 'italian')}</TableCell>
                  :
                  <TableCell className={classes.notUsed}>{t('notUsed')}</TableCell>}
                <TableCell>{formatTime(item.created, 'italian')}</TableCell>
                <TableCell>{formatTime(item.deadline, 'italian')}</TableCell>
                <TableCell>{item.value}</TableCell>
                <TableCell>{item.um === '%' ? t('percentage') : t('commandUnit')}</TableCell>
              </TableRow>
            )) : (<TableShimmer columns={3} endAction />)}
          </TableBody>
        </Table>
      </div>
      {superManager && (<CollectionFab editPath="/rates/voucher" />)}
    </PageLayout>
  );
};

export default VouchersPage;
