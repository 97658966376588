import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, FormControl, InputLabel, Select, MenuItem, Snackbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import { reportsActions } from '../store';
import { useCatch, useEffectAsync } from '../reactHelper';
import { snackBarDurationLongMs } from '../common/util/duration';
import RentsMenu from './components/RentsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const RentAdminPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const [item, setItem] = useState({});
  const [users, setUsers] = useState();
  const [userId, setUserId] = useState();
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const deviceIds = useSelector((state) => (
    state.reports.deviceIds.length
      ? state.reports.deviceIds : state.reports.deviceId
        ? [state.reports.deviceId] : selectedDeviceId
          ? [selectedDeviceId] : []
  ));

  const validate = () => deviceIds.length && userId;

  const handle = useCatch(async () => {
    const devicesToSend = {
      devices: deviceIds,
    };
    const response = await fetch('/api/rent/startRentAdmin', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `devices=${JSON.stringify(devicesToSend)}&user=${userId}`,
    });

    if (response.ok) {
      setInfoMessage(t('startRent'));
      setInfoOpen(true);
    } else {
      throw Error(await response.text());
    }
  });

  useEffectAsync(async () => {
    try {
      const response = await fetch('/api/users');
      if (response.ok) {
        setUsers(await response.json());
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  return (
    <EditItemView
      endpoint="prices"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<RentsMenu />}
      breadcrumbs={['rentAdmin']}
      customHandle={handle}
      customText="startRent"
      noCancel
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <FormControl fullWidth>
              <InputLabel>{t('user')}</InputLabel>
              <Select
                label={t('user')}
                value={userId || ''}
                onChange={(e) => setUserId(e.target.value)}
              >
                {users && users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>{user.email}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>{t('deviceTitle')}</InputLabel>
              <Select
                label={t('deviceTitle')}
                value={deviceIds}
                onChange={(e) => dispatch(reportsActions.updateDeviceIds(e.target.value))}
                multiple
              >
                {Object.values(devices).sort((a, b) => a.name.localeCompare(b.name)).map((device) => (
                  <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
      <Snackbar
        open={infoOpen}
        autoHideDuration={snackBarDurationLongMs}
        onClose={() => setInfoOpen(false)}
        message={infoMessage}
      />
    </EditItemView>
  );
};

export default RentAdminPage;
