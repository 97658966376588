import React from 'react';
import {
  Card,
  CardHeader,
  Grid,
} from '@mui/material';

const InfoCard = ({ name, data }) => (
  <Card elevation={3}>
    <Grid container>
      <Grid item xs={7}>
        <CardHeader title={name} titleTypographyProps={{ fontSize: 20 }} />
      </Grid>
      <Grid item xs={5}>
        <CardHeader title={data} titleTypographyProps={{ fontSize: 20 }} />
      </Grid>
    </Grid>
  </Card>
);

export default InfoCard;
