import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import usePriceAttributes from '../common/attributes/usePriceAttributes';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DocumentPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
  const priceAttributes = usePriceAttributes(t);

  const validate = () => item && item.price;

  return (
    <EditItemView
      endpoint="prices"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['prices']}
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.name || ''}
              onChange={(event) => setItem({ ...item, name: event.target.value })}
              label={t('sharedName')}
            />
            <TextField
              value={item.price || '0.01'}
              type="number"
              inputProps={{ step: '0.01', min: '0.01' }}
              onChange={(event) => setItem({ ...item, price: event.target.value })}
              label={t('price')}
            />
            <FormControl fullWidth>
              <InputLabel>{t('um')}</InputLabel>
              <Select label={t('um')} value={item.um} onChange={(event) => setItem({ ...item, um: event.target.value })}>
                <MenuItem value="min">{t('sharedMinutes')}</MenuItem>
                <MenuItem value="h">{t('sharedHours')}</MenuItem>
                <MenuItem value="d">{t('sharedDays')}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              value={item.priceunlock || '0'}
              type="number"
              onChange={(event) => setItem({ ...item, priceunlock: event.target.value })}
              inputProps={{ step: '0.01', min: '0' }}
              label={t('priceUnlock')}
            />
            <TextField
              value={item.endHour || setItem({ ...item, endHour: '20:00' })}
              type="time"
              onChange={(event) => setItem({ ...item, endHour: event.target.value })}
              label={t('endHour')}
            />
          </AccordionDetails>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{ ...priceAttributes }}
          />
        </Accordion>
      )}
    </EditItemView>
  );
};

export default DocumentPage;
