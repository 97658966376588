import React, { useEffect, useState, useCallback } from 'react';
import {
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BikeScooter } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { devicesActions } from '../store';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from '../settings/components/SettingsMenu';
import CardWrapper from '../common/components/CardWrapper';
import MainMap from './MainMap';
import StatusCard from '../common/components/StatusCard';
import { useEffectAsync } from '../reactHelper';
import EventsDrawer from './EventsDrawer';
import DeviceList from './DeviceList';
import MainToolbar from './MainToolbar';
import usePersistedState from '../common/util/usePersistedState';
import { useAttributePreference } from '../common/util/preferences';
import useFilter from './useFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: 313,
      top: 66,
      height: `calc(100% - ${theme.spacing(12)})`,
      width: theme.dimensions.drawerWidthDesktop,
      margin: theme.spacing(1.5),
      zIndex: 3,
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
    },
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: 'grid',
    borderRadius: 20,
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 4,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
}));

const MainPageAlt = () => {
  const main = 'mainPage';
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
  });
  const [keyword, setKeyword] = useState('');
  const mapOnSelect = useAttributePreference('mapOnSelect', true);
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [devicesOpen, setDevicesOpen] = useState(desktop);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [rents, setRents] = useState();
  const [rentsActives, setRentsActives] = useState(0);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);
  const devices = useSelector((state) => state.devices.items);
  const user = useSelector((state) => state.session.user);
  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);

  function getPositionsFromDevices(devices) {
    setFilteredDevices(devices);
    const supportPositions = [];
    devices.forEach((device) => {
      Object.entries(positions).forEach((position) => {
        if (parseInt(position[0], 10) === device.id) {
          supportPositions.push(position[1]);
        }
      });
    });
    setFilteredPositions(supportPositions);
  }

  function getRentsPositions() {
    const supportPositions = [];
    rents.forEach((rent) => {
      Object.entries(positions).forEach((position) => {
        rent.deviceIds.forEach((deviceId) => {
          if (parseInt(position[0], 10) === deviceId) {
            supportPositions.push(position[1]);
          }
        });
      });
    });
    setFilteredPositions(supportPositions);
  }

  function rentsConf(rents) {
    if (!rents) {
      setRentsActives(0);
      return;
    }
    let counterRents = 0;
    const filteredSupport = [];
    rents.forEach((rent) => {
      const dateEnd = rent.dateEnd.split('-');
      if (parseInt(dateEnd[0], 10) < 2000) {
        counterRents += rent.deviceIds.length;
        filteredSupport.push(rent);
      }
    });
    setRents(filteredSupport);
    setRentsActives(counterRents);
  }

  useEffectAsync(async () => {
    if (id) {
      try {
        const response = await fetch(`api/devices?id=${id}`);
        if (response.ok) {
          const res = await response.json();
          setFilteredDevices(res);
          getPositionsFromDevices(res);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        throw Error(error);
      }
      return;
    }
    try {
      const response = await fetch('api/devices');
      if (response.ok) {
        const res = await response.json();
        setFilteredDevices(res);
        getPositionsFromDevices(res);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
    const from = moment().startOf('day').toISOString();
    const to = moment().endOf('day').toISOString();
    const userId = user.id;
    const query = new URLSearchParams({ from, to, userId });
    try {
      const responseRent = await fetch(`/api/reportsext/rents?${query.toString()}`);
      if (responseRent.ok) {
        const resRent = await responseRent.json();
        rentsConf(resRent);
      }
    } catch (error) {
      throw Error(error);
    }
  }, [location]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
    getPositionsFromDevices(filteredDevices);
  }, [positions, desktop, mapOnSelect, selectedDeviceId]);

  useFilter(keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={[main]}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
        <Grid item xs={3} paddingTop={0}>
          <CardWrapper devices={devices} type="online" callback={(value) => getPositionsFromDevices(value)} />
        </Grid>
        <Grid item xs={3} paddingTop={0}>
          <CardWrapper devices={devices} type="offline" callback={(value) => getPositionsFromDevices(value)} />
        </Grid>
        <Grid item xs={3} paddingTop={0}>
          <CardWrapper positions={positions} devices={devices} type="lowBattery" callback={(value) => setFilteredPositions(value)} />
        </Grid>
        <Grid item xs={3} paddingTop={0}>
          <CardActionArea onClick={() => getRentsPositions()}>
            <Card elevation={3}>
              <Grid container>
                <Grid item xs={7} style={{ textAlign: 'center', alignSelf: 'center' }}>
                  <BikeScooter />
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'center' }}>
                  <CardHeader title={rentsActives} titleTypographyProps={{ fontSize: 20 }} />
                </Grid>
              </Grid>
            </Card>
          </CardActionArea>
        </Grid>
      </Grid>
      {desktop && (
        <MainMap
          filteredPositions={filteredPositions}
          selectedPosition={selectedPosition}
          onEventsClick={onEventsClick}
        />
      )}
      <div className={classes.sidebar}>
        <Paper square elevation={3} className={classes.header} style={devicesOpen ? {} : { borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}>
          <MainToolbar
            filteredDevices={filteredDevices}
            devicesOpen={devicesOpen}
            setDevicesOpen={setDevicesOpen}
            keyword={keyword}
            setKeyword={setKeyword}
            filter={filter}
            setFilter={setFilter}
            filterSort={filterSort}
            setFilterSort={setFilterSort}
            filterMap={filterMap}
            setFilterMap={setFilterMap}
          />
        </Paper>
        <div className={classes.middle}>
          {!desktop && (
            <div className={classes.contentMap}>
              <MainMap
                filteredPositions={filteredPositions}
                selectedPosition={selectedPosition}
                onEventsClick={onEventsClick}
              />
            </div>
          )}
          <Paper square className={classes.contentList} style={devicesOpen ? {} : { visibility: 'hidden' }}>
            <DeviceList devices={filteredDevices} />
          </Paper>
        </div>
      </div>
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      {selectedDeviceId && (
        <StatusCard
          deviceId={selectedDeviceId}
          position={selectedPosition}
          onClose={() => dispatch(devicesActions.select(null))}
          desktopPadding={theme.dimensions.drawerWidthDesktop}
        />
      )}
    </PageLayout>
  );
};

export default MainPageAlt;
