import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, IconButton,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { documentsActions } from '../store';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { extractDateString, nameSeparator, transformToDate } from '../common/util/formatter';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const DocumentsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/utils/notifyuser');
      if (response.ok) {
        const res = await response.json();
        dispatch(documentsActions.update(res.length));
        setItems(res);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['prices']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('surname')}</TableCell>
            <TableCell>{t('userEmail')}</TableCell>
            <TableCell>{t('dateBirth')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.columnAction} padding="none">
                <IconButton size="small" onClick={() => navigate('/userView', { state: { item } })}>
                  <Visibility />
                </IconButton>
              </TableCell>
              <TableCell>{nameSeparator(item.name)[0]}</TableCell>
              <TableCell>{nameSeparator(item.name)[1]}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{extractDateString(transformToDate(item.attributes.dateBirth))}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={3} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default DocumentsPage;
