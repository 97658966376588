import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import CollectionFab from '../settings/components/CollectionFab';
import CollectionActions from '../settings/components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useSuperManager } from '../common/util/permissions';
import SearchHeader, { filterByKeyword } from '../settings/components/SearchHeader';
import PoiMenu from './components/PoiMenu';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const ContentsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const superManager = useSuperManager();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/contents');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<PoiMenu />} breadcrumbs={['managePoi']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{`${t('title')} IT`}</TableCell>
            <TableCell>{`${t('content')} IT`}</TableCell>
            <TableCell>{`${t('title')} EN`}</TableCell>
            <TableCell>{`${t('content')} EN`}</TableCell>
            <TableCell>{`${t('title')} DE`}</TableCell>
            <TableCell>{`${t('content')} DE`}</TableCell>
            <TableCell>{`${t('title')} ES`}</TableCell>
            <TableCell>{`${t('content')} ES`}</TableCell>
            <TableCell>{`${t('title')} FR`}</TableCell>
            <TableCell>{`${t('content')} FR`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  itemId={item.id}
                  editPath="/poi/content"
                  endpoint="contents"
                  setTimestamp={setTimestamp}
                />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.title_IT}</TableCell>
              <TableCell>{item.textcontent_IT}</TableCell>
              <TableCell>{item.title_EN}</TableCell>
              <TableCell>{item.textcontent_EN}</TableCell>
              <TableCell>{item.title_DE}</TableCell>
              <TableCell>{item.textcontent_DE}</TableCell>
              <TableCell>{item.title_ES}</TableCell>
              <TableCell>{item.textcontent_ES}</TableCell>
              <TableCell>{item.title_FR}</TableCell>
              <TableCell>{item.textcontent_FR}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={3} endAction />)}
        </TableBody>
      </Table>
      {superManager && (<CollectionFab editPath="/poi/content" />)}
    </PageLayout>
  );
};

export default ContentsPage;
