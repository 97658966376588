import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Card,
  CardMedia,
} from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { Translate } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMenu from './components/PoiMenu';
import EditAttributesAccordion from '../settings/components/EditAttributesAccordion';
import { useCatch } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  tokenActions: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ContentPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const supportedLanguages = ['IT', 'EN', 'ES', 'FR', 'DE'];

  const [item, setItem] = useState();

  const validate = () => item && item.name;

  const handleFiles = useCatch(async (files) => {
    if (files.length > 0) {
      const response = await fetch(`/api/contents/${item.id}/image`, {
        method: 'POST',
        body: files[0],
      });
      if (response.ok) {
        setItem({ ...item, attributes: { ...item.attributes, image: await response.text() } });
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <EditItemView
      endpoint="contents"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<PoiMenu />}
      breadcrumbs={['managePoi']}
    >
      {item && (
        <>
          <Accordion defaultExpanded style={{ borderRadius: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('content')}
              </Typography>
            </AccordionSummary>
            {supportedLanguages.map((lang) => (
              <AccordionDetails className={classes.details}>
                <TextField
                  value={item[`title_${lang}`] || ''}
                  onChange={(event) => setItem({ ...item, [`title_${lang}`]: event.target.value })}
                  label={`${t('title')} ${lang}`}
                  InputProps={{
                    endAdornment: lang !== 'IT' && (
                      <InputAdornment position="end">
                        <div className={classes.tokenActions}>
                          <IconButton size="small" edge="end" onClick={() => {}} disabled={!item.title_IT}>
                            <Translate fontSize="small" />
                          </IconButton>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl>
                  <InputLabel>{`${t('content')} ${lang}`}</InputLabel>
                  <OutlinedInput
                    multiline
                    rows={6}
                    label={`${t('content')} ${lang}`}
                    value={item[`textcontent_${lang}`] || ''}
                    onChange={(event) => setItem({ ...item, [`textcontent_${lang}`]: event.target.value })}
                    endAdornment={lang !== 'IT' && (
                      <InputAdornment position="end">
                        <div className={classes.tokenActions}>
                          <IconButton size="small" edge="end" onClick={() => {}} disabled={!item.textcontent_IT}>
                            <Translate fontSize="small" />
                          </IconButton>
                        </div>
                      </InputAdornment>
                    )}
                  />
                </FormControl>
              </AccordionDetails>
            ))}
          </Accordion>
          {item.id && (
            <>
              {item.attributes && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('positionImage')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Card elevation={3}>
                      <CardMedia image={`/api/media/public/images/contents/${item.id}/${item.attributes.image}?dummy=${Math.floor(Math.random() * 1000)}`} style={{ height: 200 }} />
                    </Card>
                    <DropzoneArea
                      dropzoneText={t('sharedDropzoneText')}
                      acceptedFiles={['image/*']}
                      filesLimit={1}
                      onChange={handleFiles}
                      showAlerts={false}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
              <div />
            </>
          )}
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default ContentPage;
