import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { AddBusiness, BikeScooter, BookmarkAdd } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import palette from '../../common/theme/palette';
import { useSuperManager } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton
    key={link}
    component={Link}
    to={link}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: palette.primary.main, color: 'white' },
      '&.Mui-selected:hover': { backgroundColor: palette.primary.main, color: 'white' },
      borderRadius: 20,
      marginLeft: 1,
      marginRight: 1,
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const RentsMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const superManager = useSuperManager();

  return (
    <List>
      <MenuItem
        title={t('rides')}
        link="/rents/rides"
        icon={<BikeScooter style={{ color: location.pathname.startsWith('/rents/rides') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/rents/rides')}
      />
      {superManager && (
        <MenuItem
          title={t('rentAdmin')}
          link="/rents/rentAdmin"
          icon={<AddBusiness style={{ color: location.pathname.startsWith('/rents/rentAdmin') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/rents/rentAdmin')}
        />
      )}
      <MenuItem
        title={t('rentManager')}
        link="/rents/rentManager"
        icon={<BookmarkAdd style={{ color: location.pathname.startsWith('/rents/rentManager') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/rents/rentManager')}
      />
    </List>
  );
};

export default RentsMenu;
