import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AccountCircle,
  ArrowBackIos,
  ArrowForwardIos,
  Download,
  ReceiptLong,
  ZoomIn,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { useEffectAsync } from '../reactHelper';
import { parseTwoDigit, photoArrayFiller } from '../common/util/converter';
import { documentConverter, personalInfoConverter } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import CollectionActions from './components/CollectionActions';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: 50,
    bottom: 50,
    left: 50,
    right: 50,
  },
  modalTiny: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 300,
    bottom: 300,
    left: 300,
    right: 300,
  },
  modalPhone: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 250,
    bottom: 250,
    left: 50,
    right: 50,
  },
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'contain',
  },
  downloadButton: {
    position: 'absolute',
    top: 0,
    right: 50,
    zIndex: 2,
    width: 60,
    height: 60,
  },
  zoomInButton: {
    position: 'absolute',
    top: 0,
    right: 120,
    zIndex: 2,
    width: 60,
    height: 60,
  },
  rightButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    border: 0,
    width: 50,
    backgroundColor: 'hsla(0, 0%, 0%, 0.3)',
    zIndex: 2,
  },
  leftButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    border: 0,
    width: 50,
    backgroundColor: 'hsla(0, 0%, 0%, 0.3)',
    zIndex: 2,
  },
  acceptButton: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  rejectButton: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const UserViewPage = () => {
  const { state } = useLocation();
  const { item } = state;
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [documents, setDocuments] = useState([]);
  const [documentSelected, setDocumentSelected] = useState();
  const [wallet, setWallet] = useState();
  const [motivation, setMotivation] = useState();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [photoOpened, setPhotoOpened] = useState(false);
  const [photoList, setPhotoList] = useState([]);
  const [indexPhotoList, setIndexPhotoList] = useState();
  const [rejectOpen, setRejectOpen] = useState(false);

  useEffectAsync(async () => {
    const names = item.name.split(',');
    setName(names[0]);
    setSurname(names[1].substr(1, names[1].length));
    const resBilling = await fetch(`/api/billingaddresses/?userId=${item.id}`);
    if (resBilling.ok) {
      setBillingAddresses(await resBilling.json());
    } else {
      throw Error(await resBilling.text());
    }

    const resDocuments = await fetch(`/api/document/?userId=${item.id}`);
    if (resDocuments.ok) {
      const resJson = await resDocuments.json();
      const photos = photoArrayFiller(resJson);
      setPhotoList(photos);
      setDocuments(resJson);
    } else {
      throw Error(await resDocuments.text());
    }

    const resWallet = await fetch(`/api/wallets/?userId=${item.id}`);
    if (resWallet.ok) {
      setWallet(await resWallet.json());
    } else {
      throw Error(await resWallet.text());
    }
  }, [timestamp]);

  const validationDocument = async (item, valid) => {
    if (!window.confirm(`${t('areUSure')}?`)) {
      return;
    }
    if (valid) item.status = 1;
    else {
      item.status = '-1';
      item.message = motivation;
      setRejectOpen(false);
    }
    const resValidation = await fetch('/api/document/compliant', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (resValidation.ok) {
      setTimestamp(Date.now());
    } else {
      throw Error(await resValidation.text());
    }
  };

  const handleOpenPhoto = (name) => {
    setIndexPhotoList(photoList.indexOf(name));
    setPhotoOpened(true);
  };

  const goToPayments = () => navigate('/transactionsUser', { state: { item } });

  const goToPaymentsObject = {
    title: 'pagamenti',
    icon: <ReceiptLong fontSize="small" />,
    handler: goToPayments,
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsUsers']} normalBack>
      <Modal open={photoOpened} onClose={() => setPhotoOpened(false)}>
        <Card elevation={3} className={classes.modal}>
          <Button variant="contained" className={classes.downloadButton} href={`/api/media/users/${item.id}/${photoList[indexPhotoList]}`} download>
            <Download />
          </Button>
          <Button variant="contained" className={classes.zoomInButton} href={`/api/media/users/${item.id}/${photoList[indexPhotoList]}`} target="_blank">
            <ZoomIn />
          </Button>
          <CardMedia image={`/api/media/users/${item.id}/${photoList[indexPhotoList]}`} className={classes.modalContent} />
          <button
            name="button"
            type="button"
            className={classes.leftButton}
            onClick={() => {
              if (indexPhotoList - 1 >= 0) setIndexPhotoList((indexPhotoList - 1) % photoList.length);
            }}
          >
            <ArrowBackIos style={{ color: '#ffffff', marginLeft: 4 }} />
          </button>
          <button
            name="button"
            type="button"
            className={classes.rightButton}
            onClick={() => {
              if (indexPhotoList + 1 < photoList.length) setIndexPhotoList((indexPhotoList + 1) % photoList.length);
            }}
          >
            <ArrowForwardIos style={{ color: '#ffffff', marginLeft: 4 }} />
          </button>
        </Card>
      </Modal>
      <Modal open={rejectOpen} onClose={() => setRejectOpen(false)}>
        <Card elevation={3} className={phone ? classes.modalPhone : classes.modalTiny}>
          <h3 style={{ textAlign: 'center' }}>{t('rejectReason')}</h3>
          <TextField
            multiline
            rows={4}
            style={{ padding: 20 }}
            placeholder={t('placeholderMotivation')}
            onChange={(event) => setMotivation(event.target.value)}
            value={motivation || ''}
          />
          <Button variant="contained" style={{ width: 300, margin: 'auto' }} onClick={() => validationDocument(documentSelected, false)} color="error">
            {t('reject')}
          </Button>
        </Card>
      </Modal>
      <Grid container spacing={1} justifyContent="center" style={{ marginTop: 40 }}>
        {(item && item.attributes.avatar) ? (
          <Card elevation={3} style={{ borderRadius: 100 }}>
            <CardMedia image={`/api/media/users/${item.id}/${item.attributes.avatar}`} style={{ height: 100, width: 100, borderRadius: 100, overflow: 'hidden' }} />
          </Card>
        ) : <AccountCircle style={{ height: 100, width: 100 }} />}
      </Grid>
      <Grid container spacing={1} justifyContent="center" style={{ marginTop: 10 }}>
        {item && <strong>{item.name}</strong>}
      </Grid>
      <Grid container spacing={1} justifyContent="center">
        <Paper elevation={3} style={{ padding: 10, marginTop: 30, paddingBlock: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                {wallet ? (
                  <>
                    <TableCell className={classes.columnAction} />
                    <TableCell>Wallet</TableCell>
                  </>
                ) : (
                  <TableCell><Skeleton style={{ width: 120 }} /></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {wallet ? (
                  <>
                    <TableCell className={classes.columnAction} padding="none">
                      <CollectionActions
                        itemId={wallet[0].id}
                        editPath="/walletEdit"
                        endpoint="wallets"
                        setTimestamp={setTimestamp}
                        customAction={goToPaymentsObject}
                        noDelete
                      />
                    </TableCell>
                    <TableCell><strong>{`${parseTwoDigit(wallet[0].balance / 100)}€`}</strong></TableCell>
                  </>
                ) : (
                  <TableCell><Skeleton style={{ width: 120 }} /></TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid md={5} marginTop={10}>
          {item && (
            <Paper elevation={3} style={{ padding: 10, paddingBottom: 0 }}>
              <h3 style={{ textAlign: 'center' }}>{t('personalInfo')}</h3>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('sharedName')}</TableCell>
                    <TableCell><strong>{name}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('surname')}</TableCell>
                    <TableCell><strong>{surname}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-mail</TableCell>
                    <TableCell><strong>{item.email}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone</TableCell>
                    <TableCell><strong>{item.phone}</strong></TableCell>
                  </TableRow>
                  {Object.getOwnPropertyNames(item.attributes).filter((it) => (it !== 'avatar' && it !== 'id')).map((property) => (
                    <TableRow key={property}>
                      <TableCell>{t(property)}</TableCell>
                      <TableCell><strong>{personalInfoConverter(property, item.attributes[property])}</strong></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
        <Grid md={5} marginTop={10}>
          {billingAddresses.length > 0 && (
            <Paper elevation={3} style={{ padding: 10, paddingBottom: 0 }}>
              <h3 style={{ textAlign: 'center' }}>{t('billingInfo')}</h3>
              <Table>
                <TableBody>
                  {Object.getOwnPropertyNames(billingAddresses[0]).filter((it) => it !== 'id').map((property) => (
                    <TableRow key={property}>
                      <TableCell>{t(property)}</TableCell>
                      <TableCell><strong>{billingAddresses[0][property]}</strong></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
      </Grid>
      {documents.length > 0 && (documents.map((singleDocument) => (
        <Grid container spacing={1} justifyContent="center" style={{ marginTop: 40 }}>
          <Grid md={11}>
            <Paper elevation={3} style={{ padding: 10 }}>
              <Grid container spacing={1} justifyContent="center" style={{ marginTop: 10, marginBottom: 10 }}>
                <strong>{t('document')}</strong>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid md={7} style={{ paddingRight: 10 }}>
                  <Table>
                    <TableBody>
                      {Object.getOwnPropertyNames(singleDocument).filter((it) => (it !== 'attributes' && it !== 'id' && it !== 'message')).map((property) => (
                        <TableRow key={property}>
                          <TableCell>{t(property)}</TableCell>
                          <TableCell><strong>{documentConverter(property, singleDocument[property])}</strong></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid md={5} xs={12}>
                  {singleDocument.attributes && Object.getOwnPropertyNames(singleDocument.attributes).map((type) => (
                    <CardActionArea onClick={() => handleOpenPhoto(singleDocument.attributes[type])}>
                      <Card elevation={3}>
                        <CardMedia image={`/api/media/users/${item.id}/${singleDocument.attributes[type]}`} style={{ height: 200 }} />
                      </Card>
                    </CardActionArea>
                  ))}
                </Grid>
              </Grid>
              <Grid container justifyContent="space-around" style={{ position: 'relative', marginTop: 20 }}>
                <Grid xs={5} style={{ position: 'relative', height: 40 }}>
                  <Button variant="contained" className={classes.acceptButton} onClick={() => validationDocument(singleDocument, true)}>
                    {t('accept')}
                  </Button>
                </Grid>
                <Grid xs={5} style={{ position: 'relative', height: 40 }}>
                  <Button variant="contained" className={classes.rejectButton} onClick={() => { setDocumentSelected(singleDocument); setRejectOpen(true); }} color="error">
                    {t('reject')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )))}
    </PageLayout>
  );
};

export default UserViewPage;
