import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';

const LinkPositionalField = ({
  label,
  endpoint,
  add,
  titleGetter = (item) => item.name,
}) => {
  const [items, setItems] = useState();
  const [open, setOpen] = useState(false);

  useEffectAsync(async () => {
    const response = await fetch(endpoint);
    if (response.ok) {
      setItems(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, []);

  return (
    <Autocomplete
      loading={!items}
      options={items || []}
      getOptionLabel={(item) => titleGetter(item)}
      open={open}
      renderInput={(params) => <TextField {...params} label={label} />}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(_, value) => {
        if (value) add(value);
      }}
    />
  );
};

export default LinkPositionalField;
