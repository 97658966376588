import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, MenuItem, FormControl, InputLabel, Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../reactHelper';
import RatesMenu from './components/RatesMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const VoucherPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();

  const [item, setItem] = useState({ um: '%25', deadline: moment().locale('en').format(moment.HTML5_FMT.DATETIME_LOCAL) });
  const [users, setUsers] = useState();

  useEffectAsync(async () => {
    try {
      const response = await fetch('/api/utils/manager');
      if (response.ok) {
        setUsers(await response.json());
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const handle = useCatch(async () => {
    const deadlineMoment = moment(item.deadline, moment.HTML5_FMT.DATETIME_LOCAL);
    const response = await fetch('api/vouchers/generate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `count=${item.count}&value=${item.value}&um=${item.um}&assigned=${item.userIdassigned}&deadline=${deadlineMoment.toISOString()}`,
    });

    if (response.ok) {
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  const validate = () => item && item.count && item.value && item.um && item.userIdassigned && item.deadline;

  return (
    <EditItemView
      endpoint="vouchers"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<RatesMenu />}
      breadcrumbs={['vouchers']}
      customHandle={handle}
      customText="generate"
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <FormControl fullWidth>
              <InputLabel>{t('user')}</InputLabel>
              <Select
                label={t('user')}
                value={item.userIdassigned || ''}
                onChange={(event) => setItem({ ...item, userIdassigned: event.target.value })}
              >
                {users && users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              value={item.value || ''}
              type="number"
              inputProps={{ step: '0.1', min: '0' }}
              onChange={(event) => setItem({ ...item, value: event.target.value })}
              label={t('stateValue')}
            />
            <FormControl fullWidth>
              <InputLabel>{t('um')}</InputLabel>
              <Select label={t('um')} value={item.um} onChange={(event) => setItem({ ...item, um: event.target.value })}>
                <MenuItem value="%25">{t('percentage')}</MenuItem>
                <MenuItem value="cad">{t('commandUnit')}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              value={item.count || ''}
              type="number"
              inputProps={{ min: '0' }}
              onChange={(event) => setItem({ ...item, count: event.target.value })}
              label={t('quantity')}
            />
            <TextField
              value={item.deadline}
              type="datetime-local"
              onChange={(event) => setItem({ ...item, deadline: event.target.value })}
              label={t('userExpirationTime')}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default VoucherPage;
