import React from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import logo from '../resources/images/logoMain.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '30%',
    alignItems: 'center',
    background: theme.palette.primary.main,

    [theme.breakpoints.down('lg')]: {
      width: '0px',
      height: '0',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%',
    paddingBottom: theme.spacing(0),
    flex: 1,
    background: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(0),
    },
  },
  form: {
    background: theme.palette.secondary.contrastText,
    borderRadius: 5,
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <div className={classes.sidebar}>
        {!useMediaQuery(theme.breakpoints.down('lg')) && (
          <img
            width="30%"
            src={logo}
            alt="logo"
          />
        )}
      </div>
      <Paper className={classes.paper}>
        <form className={classes.form}>{children}</form>
      </Paper>
    </main>
  );
};

export default LoginLayout;
