import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Divider,
  Card,
  CardMedia,
} from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCatch, useEffectAsync } from '../reactHelper';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMenu from './components/PoiMenu';
import EditAttributesAccordion from '../settings/components/EditAttributesAccordion';
import LinkPositionalField from '../common/components/LinkPositionalField';
import PositionalTable from './components/PositionalTable';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const PoiPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const dayClosedModel = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  const dayClosedModelAllClosed = {
    monday: 'closed',
    tuesday: 'closed',
    wednesday: 'closed',
    thursday: 'closed',
    friday: 'closed',
    saturday: 'closed',
    sunday: 'closed',
  };

  const dayClosedModelAllOpen = {
    monday: 'open',
    tuesday: 'open',
    wednesday: 'open',
    thursday: 'open',
    friday: 'open',
    saturday: 'open',
    sunday: 'open',
  };

  const [item, setItem] = useState();
  const [openSwitch, setOpenSwitch] = useState('ignore');
  const [dayClosedStatus, setDayClosedStatus] = useState(dayClosedModel);
  const [attractions, setAttractions] = useState([]);
  const [contents, setContents] = useState([]);
  const [tags, setTags] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const addAttraction = (value) => {
    const attrTemp = [...attractions];
    attrTemp.push(value);
    setAttractions(attrTemp);
  };

  const addContent = (value) => {
    const contTemp = [...contents];
    contTemp.push(value);
    setContents(contTemp);
  };

  const addTag = (value) => {
    const tagTemp = [...tags];
    tagTemp.push(value);
    setTags(tagTemp);
  };

  const handleSave = useCatch(async () => {
    let url = '/api/poi';
    if (id) {
      url += `/${id}`;
    }

    const response = await fetch(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      if (!id) {
        navigate(-1);
        return;
      }
      let bodyContent = `poiid=${item.id}`;
      attractions.forEach((attraction) => {
        bodyContent += `&poiattractions=${attraction.id}`;
      });
      const res = await fetch('/api/poi/attractions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: bodyContent,
      });
      if (res.ok) {
        let bodyContentContents = `poiid=${item.id}`;
        contents.forEach((content) => {
          bodyContentContents += `&contents=${content.id}`;
        });
        const resContent = await fetch('/api/poi/contents', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: bodyContentContents,
        });
        if (resContent.ok) {
          let bodyContentTags = `poiid=${item.id}`;
          tags.forEach((tag) => {
            bodyContentTags += `&tags=${tag.id}`;
          });
          const resTag = await fetch('/api/poi/tags', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: bodyContentTags,
          });
          if (resTag.ok) {
            navigate(-1);
          }
        }
      }
    } else {
      throw Error(await response.text());
    }
  });

  const handleFiles = useCatch(async (files) => {
    if (files.length > 0) {
      const response = await fetch(`/api/poi/${item.id}/image`, {
        method: 'POST',
        body: files[0],
      });
      if (response.ok) {
        setItem({ ...item, attributes: { ...item.attributes, image: await response.text() } });
      } else {
        throw Error(await response.text());
      }
    }
  });

  const validate = () => item && item.name && item.latitude && item.longitude;

  useEffectAsync(async () => {
    setItem({ ...item, attraction: 0 });
  }, []);

  return (
    <EditItemView
      endpoint="poi"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<PoiMenu />}
      customHandle={handleSave}
      customText="sharedSave"
      getAttractions
      setAttractions={setAttractions}
      getContents
      setContents={setContents}
      getTags
      setTags={setTags}
      breadcrumbs={['managePoi']}
    >
      {item && (
        <>
          <Accordion defaultExpanded style={{ borderRadius: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.latitude || ''}
                onChange={(event) => setItem({ ...item, latitude: event.target.value })}
                label={t('positionLatitude')}
              />
              <TextField
                value={item.longitude || ''}
                onChange={(event) => setItem({ ...item, longitude: event.target.value })}
                label={t('positionLongitude')}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('notRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.address || ''}
                onChange={(event) => setItem({ ...item, address: event.target.value })}
                label={t('address')}
              />
            </AccordionDetails>
            <AccordionSummary>
              <Typography variant="subtitle1">
                {t('openings')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <RadioGroup
                onChange={(event) => {
                  setOpenSwitch(event.target.value);
                  switch (event.target.value) {
                    case 'ignore':
                      setItem({ ...item, openings: {} });
                      break;
                    case 'open':
                      break;
                    case 'aopen':
                      setItem({ ...item, openings: dayClosedModelAllOpen });
                      break;
                    case 'closed':
                      setItem({ ...item, openings: dayClosedModelAllClosed });
                      break;
                    default:
                      break;
                  }
                }}
                value={openSwitch}
              >
                <FormControlLabel
                  value="ignore"
                  control={<Radio />}
                  label="Ignore"
                />
                <FormControlLabel
                  value="closed"
                  control={<Radio />}
                  label="Closed"
                />
                <FormControlLabel
                  value="aopen"
                  control={<Radio />}
                  label="Sempre aperto"
                />
                <FormControlLabel
                  value="open"
                  control={<Radio />}
                  label="Aperto"
                />
              </RadioGroup>
              {openSwitch === 'open' && (
                <>
                  <Divider />
                  {Object.keys(dayClosedModel).map((value) => (
                    <>
                      <Typography>
                        {t(value)}
                      </Typography>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={dayClosedStatus[value]}
                            onChange={(event) => {
                              setDayClosedStatus({ ...dayClosedStatus, [value]: event.target.checked });
                              if (event.target.checked) {
                                setItem({ ...item, openings: { ...item.openings, [value]: 'closed' } });
                              } else {
                                setItem({ ...item, openings: { ...item.openings, [value]: '' } });
                              }
                            }}
                          />
                          )}
                        label={t('closed')}
                      />
                      <span style={{ fontSize: 15 }}>es. &quot;09:30-12:30,16:30-19:30&quot;</span>
                      <TextField
                        disabled={dayClosedStatus[value]}
                        value={item.openings ? item.openings[value] || '' : ''}
                        onChange={(event) => setItem({ ...item, openings: { ...item.openings, [value]: event.target.value } })}
                        label={t('openings')}
                      />
                      <Divider />
                    </>
                  ))}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          {item.id && (
            <>
              {item.attributes && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('positionImage')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Card elevation={3}>
                      <CardMedia image={`/api/media/public/images/poi/${item.id}/${item.attributes.image}?dummy=${Math.floor(Math.random() * 1000)}`} style={{ height: 200 }} />
                    </Card>
                    <DropzoneArea
                      dropzoneText={t('sharedDropzoneText')}
                      acceptedFiles={['image/*']}
                      filesLimit={1}
                      onChange={handleFiles}
                      showAlerts={false}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('attractions')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkPositionalField endpoint="/api/poi/attractions" add={addAttraction} label={t('attractions')} />
                  <PositionalTable items={attractions} setItems={setAttractions} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('content')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkPositionalField endpoint="/api/contents" add={addContent} label={t('contents')} />
                  <PositionalTable items={contents} setItems={setContents} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    Tags
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkPositionalField endpoint="/api/tags" add={addTag} label="Tags" />
                  <PositionalTable items={tags} setItems={setTags} />
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default PoiPage;
