import { useSelector } from 'react-redux';

export const useAdministrator = () => useSelector((state) => {
  const admin = state.session.user.administrator;
  return admin;
});

export const useSuperManager = () => useSelector((state) => {
  const admin = state.session.user.administrator;
  const superManager = ((state.session.user.userLimit && state.session.user.deviceLimit) || 0) !== 0;
  return admin || superManager;
});

export const useManager = () => useSelector((state) => {
  const admin = state.session.user.administrator;
  const manager = (state.session.user.deviceLimit || 0) !== 0;
  return admin || manager;
});

export const useSharing = () => useSelector((state) => state.session.server.attributes.sharing);

export const useSubscriptions = () => useSelector((state) => state.session.server.attributes.subscriptions);

export const useAutoModule = () => useSelector((state) => state.session.server.attributes.autoModule);

export const useGuideModule = () => useSelector((state) => state.session.server.attributes.guide);

export const useEventsModule = () => useSelector((state) => state.session.server.attributes.events);

export const useDeviceReadonly = () => useSelector((state) => {
  const admin = state.session.user.administrator;
  const serverReadonly = state.session.server.readonly;
  const userReadonly = state.session.user.readonly;
  const serverDeviceReadonly = state.session.server.deviceReadonly;
  const userDeviceReadonly = state.session.user.deviceReadonly;
  return !admin && (serverReadonly || userReadonly || serverDeviceReadonly || userDeviceReadonly);
});

export const useRestriction = (key) => useSelector((state) => {
  const admin = state.session.user.administrator;
  const serverValue = state.session.server[key];
  const userValue = state.session.user[key];
  return !admin && (serverValue || userValue);
});
