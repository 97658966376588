import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Grid, Skeleton } from '@mui/material';
import ReportFilter from './components/ReportFilter';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch, useEffectAsync } from '../reactHelper';
import ReportObjects from './common/reportObjects';

const AdvancedReportPage = () => {
  const [usersRent, setUsersRent] = useState(null);
  const [quantityVehicle, setQuantityVehicle] = useState(null);
  const [typeRent, setTypeRent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finalUsers, setFinalUsers] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [finalUsersRent, setFinalUsersRent] = useState(null);
  const [rentsForDay, setRentsForDay] = useState(null);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/users');
      if (response.ok) {
        const users = await response.json();
        const usersFiltered = users.filter((user) => user.userLimit === 0 && user.deviceLimit === 0);
        setFinalUsers(usersFiltered);
        setTotalUsers(usersFiltered.length);
        setLoading(false);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCatch(async ({ from, to, userIds }) => {
    const users = [];
    const query = new URLSearchParams({ from, to });
    userIds.forEach((userId) => {
      const user = userId.split(',');
      query.append('userId', user[0]);
      users.push({ name: user[1], numberOfRent: 0 });
    });
    try {
      const response = await fetch(`/api/reportsext/rents?${query.toString()}`);
      if (response.ok) {
        const rides = await response.json();
        let singleRide = 0;
        let multipleRide = 0;
        let rentHour = 0;
        let rentDaily = 0;
        const datas = new ReportObjects();
        const finalUsersCopy = finalUsers;
        for (let i = rides.length - 1; i > -1; i -= 1) {
          if (rides[i].rideStatus) {
            if (rides[i].rideStatus === 'pending') {
              continue;
            }
          }
          const dateEnd = rides[i].dateEnd.split('-');
          if (parseInt(dateEnd[0], 10) < 2000) {
            continue;
          }
          const date = new Date(rides[i].dateStart);
          datas.daysOfWeek[date.getDay()].value += 1;
          const rentsPerManagerIndex = users.findIndex((user) => user.name === rides[i].manager);
          users[rentsPerManagerIndex].numberOfRent += 1;

          const finalUserIndex = finalUsersCopy.findIndex((user) => user.email === rides[i].rentUserEmail);
          if (finalUserIndex >= 0) finalUsersCopy.splice(finalUserIndex, 1);

          if (rides[i].deviceIds.length > 1) multipleRide += 1;
          else singleRide += 1;

          if (rides[i].rideStatus === 'active') rentDaily += 1;
          else rentHour += 1;
        }
        datas.typeRent[0].value = rentHour;
        datas.typeRent[1].value = rentDaily;
        setTypeRent(datas.typeRent);

        datas.numberRentVehicles[0].value = singleRide;
        datas.numberRentVehicles[1].value = multipleRide;
        setQuantityVehicle(datas.numberRentVehicles);
        setRentsForDay(datas.daysOfWeek);

        const numberFinalUsersRent = totalUsers - finalUsersCopy.length;
        const numberFinalUsersNotRent = totalUsers - numberFinalUsersRent;
        setUsersRent(users);

        datas.finalUsersRent[0].value = numberFinalUsersRent;
        datas.finalUsersRent[1].value = numberFinalUsersNotRent;
        setFinalUsersRent(datas.finalUsersRent);
      }
    } catch (error) {
      throw Error(error);
    }
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'advanced']}>
      {loading ? <Skeleton variant="text" style={{ margin: 30 }} /> : <ReportFilter handleSubmit={handleSubmit} includeManagers ignoreDevice showOnly multiManager />}
      {usersRent && (
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
          <Grid item xs={12} md={12} paddingTop={0}>
            <ResponsiveContainer height={350}>
              <BarChart
                data={usersRent}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="numberOfRent" fill="#1b5e20" name="Noleggi per utente" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0} style={{ overflow: 'hidden' }}>
            <ResponsiveContainer height={350}>
              <BarChart
                data={rentsForDay}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#1b5e20" name="Noleggi per giorno settimana" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={350}>
              <PieChart>
                <Pie
                  data={quantityVehicle}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Cell key={quantityVehicle[0].name} fill="#1b5e20" />
                  <Cell key={quantityVehicle[1].name} fill="#ff7514" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={350}>
              <PieChart>
                <Pie
                  data={typeRent}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Cell key={typeRent[0].name} fill="#1b5e20" />
                  <Cell key={typeRent[1].name} fill="#ff7514" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <ResponsiveContainer height={350}>
              <PieChart>
                <Pie
                  data={finalUsersRent}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Cell key={finalUsersRent[0].name} fill="#1b5e20" />
                  <Cell key={finalUsersRent[1].name} fill="#ff7514" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default AdvancedReportPage;
