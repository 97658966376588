import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'documents',
  initialState: {
    newDocuments: 0,
  },
  reducers: {
    update(state, action) {
      state.newDocuments = action.payload;
    },
  },
});

export { actions as documentsActions };
export { reducer as documentsReducer };
