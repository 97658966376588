import { useEffect } from 'react';
import { map } from '../core/MapView';
import './heatmap.css';

function removeHeatLayer() {
  const control = map.getSource('positions');
  if (control) {
    map.removeLayer('positions-heat');
    map.removeSource('positions');
  }
}

const MapHeatmap = ({ data }) => {
  useEffect(() => {
    removeHeatLayer();
    map.addSource('positions', {
      type: 'geojson',
      data,
    });

    map.addLayer(
      {
        id: 'positions-heat',
        type: 'heatmap',
        source: 'positions',
        maxzoom: 24,
        paint: {
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(255,231,10,0)',
            0.2,
            'rgb(255,231,10)',
            0.4,
            'rgb(237,176,33)',
            0.6,
            'rgb(247,127,57)',
            0.8,
            'rgb(242,93,34)',
            1,
            'rgb(245,7,37)',
          ],
          'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            0,
            9,
            6,
          ],
        },
      },
    );
  }, [data]);

  useEffect(() => () => removeHeatLayer(), []);
};

export default MapHeatmap;
