import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, FormControl, InputLabel, Select, MenuItem, Skeleton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import { reportsActions } from '../store';
import { useCatch, useEffectAsync } from '../reactHelper';
import RentsMenu from './components/RentsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const RentManagerPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const [credit, setCredit] = useState([]);
  const [customUser, setCustomUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [item, setItem] = useState();
  const deviceIds = useSelector((state) => (
    state.reports.deviceIds.length
      ? state.reports.deviceIds : state.reports.deviceId
        ? [state.reports.deviceId] : selectedDeviceId
          ? [selectedDeviceId] : []
  ));

  const validate = () => deviceIds.length && (credit.length > 0 ? credit[0].quantity > 10 : false);

  useEffectAsync(async () => {
    try {
      const response = await fetch('/api/credits');
      if (response.ok) {
        setCredit(await response.json());
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const handle = useCatch(async () => {
    setIsLoading(true);
    const devicesToSend = {
      devices: deviceIds,
    };
    const response = await fetch('api/rent/startManager', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `devices=${JSON.stringify(devicesToSend)}&note=${item.note}`,
    });

    if (response.ok) {
      setCustomUser(await response.json());
    } else {
      throw Error(await response.text());
    }
    setIsLoading(false);
  });

  return (
    <EditItemView
      endpoint="prices"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<RentsMenu />}
      breadcrumbs={['rentManager']}
      customHandle={handle}
      customText="startRent"
      noCancel
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={credit.length > 0 ? credit[0].quantity : ''}
              label={t('credits')}
              disabled
            />
            <FormControl fullWidth>
              <InputLabel>{t('deviceTitle')}</InputLabel>
              <Select
                label={t('deviceTitle')}
                value={deviceIds}
                onChange={(e) => dispatch(reportsActions.updateDeviceIds(e.target.value))}
                multiple
              >
                {Object.values(devices).sort((a, b) => a.name.localeCompare(b.name)).map((device) => (
                  <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              type="string"
              onChange={(event) => setItem({ ...item, note: event.target.value })}
              label={t('note')}
              multiline
            />
            {isLoading ?
              <Skeleton height={60} />
              :
              (
                <>
                  {customUser && (
                    <>
                      <TextField
                        value={customUser.email}
                        label={t('userEmail')}
                        disabled
                      />
                      <TextField
                        value={customUser.password}
                        label={t('userPassword')}
                        disabled
                      />
                    </>
                  )}
                  <div />
                </>
              )}
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default RentManagerPage;
