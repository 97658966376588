import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';

const LinkSingleField = ({
  label,
  endpointAll,
  endpointLinked,
  baseId,
  keyBase,
  keyLink,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
}) => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState();
  const [linked, setLinked] = useState();

  useEffectAsync(async () => {
    if (active) {
      const response = await fetch(endpointAll);
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [active]);

  useEffectAsync(async () => {
    if (active) {
      const response = await fetch(endpointLinked);
      if (response.ok) {
        const res = await response.json();
        setLinked(res[0]);
      } else {
        throw Error(await response.text());
      }
    }
  }, [active]);

  const createBody = (linkId) => {
    const body = {};
    body[keyBase] = baseId;
    body[keyLink] = linkId;
    return body;
  };

  const onChange = async (value) => {
    if (!value) {
      const response = await fetch('/api/permissions', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(createBody(linked.id)),
      });

      if (!response.ok) {
        throw Error(await response.text());
      }
      setLinked(null);
      return;
    }
    if (linked && linked.id !== value.id) {
      const response = await fetch('/api/permissions', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(createBody(linked.id)),
      });

      if (!response.ok) {
        throw Error(await response.text());
      }
    }

    const response = await fetch('/api/permissions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(createBody(value.id)),
    });

    if (!response.ok) {
      throw Error(await response.text());
    }

    setLinked(value);
  };

  return (
    <Autocomplete
      loading={active && !items}
      isOptionEqualToValue={(i1, i2) => keyGetter(i1) === keyGetter(i2)}
      options={items || []}
      getOptionLabel={(item) => titleGetter(item)}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(_, value) => onChange(value)}
      value={(items && linked) || null}
      open={open}
      onOpen={() => {
        setOpen(true);
        setActive(true);
      }}
      onClose={() => setOpen(false)}
    />
  );
};

export default LinkSingleField;
