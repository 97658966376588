import React, { useEffect, useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, IconButton,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Publish } from '@mui/icons-material';
import { useEffectAsync } from '../reactHelper';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import CollectionFab from '../settings/components/CollectionFab';
import CollectionActions from '../settings/components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useAdministrator, useSuperManager } from '../common/util/permissions';
import SearchHeader, { filterByKeyword } from '../settings/components/SearchHeader';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import DevicesManagementMenu from './components/DevicesManagementMenu';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  offline: {
    color: '#FF0000',
  },
  online: {
    color: theme.palette.primary.main,
  },
}));

const StationsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();

  const admin = useAdministrator();
  const superManager = useSuperManager();
  const positions = useSelector((state) => state.session.positions);
  const [oldPositions, setOldPositions] = useState(null);
  const [init, setInit] = useState(0);

  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchKeyword, setSearchKeyword] = useState('');
  const [stations, setStations] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleShowMap = (itemId) => {
    navigate(`/${itemId}`);
  };

  const showMap = {
    title: t('mapTitle'),
    icon: (<MapIcon fontSize="small" />),
    handler: handleShowMap,
  };

  const updateStations = () => {
    if (stations && positions && oldPositions) {
      const finded = stations.filter((station) => oldPositions[station.id].id !== positions[station.id].id);
      if (finded.length > 0) {
        setTimestamp(Date.now());
      }
      setOldPositions(positions);
    }
  };

  useEffectAsync(async () => {
    if (init < 1) {
      setLoading(true);
    }
    try {
      const response = await fetch('/api/devices/ext/stations');
      if (response.ok) {
        const stations = await response.json();
        stations.forEach((station) => {
          if (station.attributes.deviceImage) {
            delete station.attributes.deviceImage;
          }
        });
        setStations(stations);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  useEffect(() => {
    if (init > 1) {
      updateStations();
    } else {
      setOldPositions(positions);
      setInit(init + 1);
    }
  }, [positions]);

  const getDeviceName = (station, id) => {
    const device = station.deviceLastPositionList.filter((device) => device.id === parseInt(id, 10));
    return device[0].name;
  };

  return (
    <PageLayout menu={<DevicesManagementMenu />} breadcrumbs={['devices']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            {superManager && <TableCell className={classes.columnAction} />}
            <TableCell className={classes.columnAction} />
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('deviceStatus')}</TableCell>
            <TableCell>dispositivi</TableCell>
            <TableCell>{t('deviceLastUpdate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? stations.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              {superManager &&
                (
                  <TableCell className={classes.columnAction} padding="none">
                    <IconButton size="small" onClick={() => navigate(`/devicesManagement/command-send/${item.id}`)}>
                      <Publish />
                    </IconButton>
                  </TableCell>
                )}
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  itemId={item.id}
                  editPath={superManager ? '/devicesManagement/device' : null}
                  endpoint="devices"
                  setTimestamp={setTimestamp}
                  customAction={showMap}
                  noDelete={!admin}
                />
              </TableCell>
              <TableCell><img src={mapIcons[mapIconKey(item.category)]} alt="" /></TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell className={item.status !== 'online' ? classes.offline : classes.online}>{item.status}</TableCell>
              {stations && <TableCell>{Object.entries(item.attributes).map(([index, device]) => <div key={index}>{`${index}) ${device !== 'empty' ? getDeviceName(item, device) : device}`}</div>)}</TableCell>}
              <TableCell>{formatTime(item.lastUpdate, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
      {admin && (<CollectionFab editPath="/devicesManagement/device" />)}
    </PageLayout>
  );
};

export default StationsPage;
