import React, { useState } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Card, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReportFilter from './components/ReportFilter';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  centerText: {
    textAlign: 'center',
  },
}));

const RidesReportPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const [items, setItems] = useState(null);
  const [payments, setPayments] = useState(null);
  const [singleValues, setSingleValues] = useState(null);
  const colorsPayments = ['#1b5e20', '#f44336'];

  const handleSubmit = useCatch(async ({ from, to, userId }) => {
    const query = new URLSearchParams({ from, to, userId });
    try {
      const response = await fetch(`/api/reportsext/rents?${query.toString()}`);
      if (response.ok) {
        const rides = await response.json();
        const itemSupport = [];
        let totalPaymentsOK = 0;
        let totalPaymentsFail = 0;
        let totalRides = 0;
        let totalDuration = 0;
        let ridesFailed = 0;
        let inRide = 0;
        for (let i = rides.length - 1; i > -1; i -= 1) {
          const date = formatTime(rides[i].dateStart, 'date');
          const dateEnd = rides[i].dateEnd.split('-');
          let controlPayments = false;
          if (rides[i].rideStatus) {
            if (rides[i].rideStatus === 'pending') {
              continue;
            }
          }
          if (parseInt(dateEnd[0], 10) < 2000) {
            inRide += 1;
            continue;
          }
          for (let h = 0; h < rides[i].payments.length; h += 1) {
            if (rides[i].payments[h].status === 'succeeded' || rides[i].payments[h].status === 'rent_from_admin') controlPayments = true;
          }
          if (!controlPayments) {
            ridesFailed += 1;
            continue;
          }
          const dataItem = itemSupport.find((element) => element.date === date);
          // se trovo un oggetto con la stessa data aggiorno i valori,
          // altrimenti ne aggiungo uno nuovo.
          if (dataItem !== undefined) {
            dataItem.totalAmounts += Math.round((rides[i].payments[0].amount / 100) * 100) / 100;
            dataItem.totalRides += 1;
            totalRides += 1;
            for (let index = 0; index < rides[i].payments.length; index += 1) {
              if (rides[i].payments[index].status === 'succeeded') totalPaymentsOK += 1;
              else if (!rides[i].payments[index].status === 'rent_from_admin') totalPaymentsFail += 1;
            }
            dataItem.totalPayments += rides[i].payments.length;
            dataItem.totalDuration += rides[i].duration;
            totalDuration += rides[i].duration;
            dataItem.avgDuration = Math.round((dataItem.totalDuration / dataItem.totalRides) * 100) / 100;
          } else {
            for (let index = 0; index < rides[i].payments.length; index += 1) {
              if (rides[i].payments[index].status === 'succeeded' || rides[i].payments[index].status === 'rent_from_admin') totalPaymentsOK += 1;
              else totalPaymentsFail += 1;
            }
            totalRides += 1;
            totalDuration += rides[i].duration;
            itemSupport.push({
              date,
              totalAmounts: Math.round((rides[i].payments[0].amount / 100) * 100) / 100,
              totalRides: 1,
              totalPayments: rides[i].payments.length,
              totalDuration: rides[i].duration,
              avgDuration: rides[i].duration,
            });
          }
        }
        setSingleValues({
          inRide,
          ridesFailed,
          totalDuration,
          totalRides,
        });
        setPayments([
          {
            name: `${t('payments')} ${t('succeeded')}`,
            value: totalPaymentsOK,
          },
          {
            name: `${t('payments')} ${t('failed')}`,
            value: totalPaymentsFail,
          },
        ]);
        setItems(itemSupport);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'rides']}>
      <ReportFilter handleSubmit={handleSubmit} includeManagers ignoreDevice showOnly />
      {items && (
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
          <Grid item xs={12} md={4} paddingTop={0}>
            <Card style={{ background: 'none', boxShadow: 'none' }}>
              <h3 className={classes.centerText}>{`${t('totalRents')}:`}</h3>
              <h2 className={classes.centerText}>{singleValues.totalRides}</h2>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} paddingTop={0}>
            <Card style={{ background: 'none', boxShadow: 'none' }}>
              <h3 className={classes.centerText}>{`${t('totalDuration')}:`}</h3>
              <h2 className={classes.centerText}>{`${singleValues.totalDuration} minuti`}</h2>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} paddingTop={0}>
            <Card style={{ background: 'none', boxShadow: 'none' }}>
              <h3 className={classes.centerText}>{`${t('avgDuration')}:`}</h3>
              <h2 className={classes.centerText}>{`${Math.round((singleValues.totalDuration / singleValues.totalRides) * 100) / 100} minuti`}</h2>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <Card style={{ background: 'none', boxShadow: 'none' }}>
              <h3 className={classes.centerText}>{`${t('inRide')}:`}</h3>
              <h2 className={classes.centerText}>{singleValues.inRide}</h2>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} paddingTop={0}>
            <Card style={{ background: 'none', boxShadow: 'none' }}>
              <h3 className={classes.centerText}>{`${t('failRide')}:`}</h3>
              <h2 className={classes.centerText}>{singleValues.ridesFailed}</h2>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <BarChart
                data={items}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalAmounts" fill="#1b5e20" name={`${t('total')} ${t('payments')} €`} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <AreaChart
                data={items}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="totalRides" fill="#1b5e20" stroke="#000000" name={t('rides')} />
              </AreaChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0}>
            <ResponsiveContainer height={400}>
              <PieChart>
                <Pie
                  data={payments}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {payments.map((entry, index) => (
                    <Cell key={entry.name} fill={colorsPayments[index % colorsPayments.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={0} style={{ overflow: 'hidden' }}>
            <ResponsiveContainer height={400}>
              <AreaChart
                data={items}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="totalDuration" fill="#e9ce2c" stroke="#000000" name={`${t('reportDuration')} ${t('sharedMinutes')}`} />
              </AreaChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default RidesReportPage;
