import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { parseTwoDigit, transactionTypeSelector } from '../common/util/converter';
import { formatTime } from '../common/util/formatter';

const TransactionsUserPage = () => {
  const { state } = useLocation();
  const { item } = state;
  const t = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const bodyContent = `user=${item.id}`;
      const response = await fetch('/api/users/rent/payments', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: bodyContent,
      });
      if (response.ok) {
        const res = await response.json();
        setTransactions(res);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['prices']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('date')}</TableCell>
            <TableCell>{t('paymentMode')}</TableCell>
            <TableCell>{t('sharedType')}</TableCell>
            <TableCell>{t('stateValue')}</TableCell>
            <TableCell>{t('status')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? transactions.filter(filterByKeyword(searchKeyword)).map((transaction) => (
            <TableRow key={transaction.id}>
              <TableCell>{formatTime(transaction.created * 1000, 'DD-MM-YYYY - hh:mm:ss')}</TableCell>
              <TableCell><strong>{transaction.idPayment === 'wallet' ? 'Wallet' : 'Cards'}</strong></TableCell>
              <TableCell>{transactionTypeSelector(transaction)}</TableCell>
              <TableCell><strong>{`${parseTwoDigit(transaction.amount / 100)}€`}</strong></TableCell>
              <TableCell style={transaction.status === 'succeeded' ? { color: '#00C000' } : { color: '#FF0000' }}>{transaction.status}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={3} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default TransactionsUserPage;
