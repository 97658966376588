import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import { reportsActions } from '../store';
import { useCatch, useEffectAsync } from '../reactHelper';
import RatesMenu from './components/RatesMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const CreditPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const userId = useSelector((state) => state.reports.userId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();
  const [add, setAdd] = useState(0);

  const [item, setItem] = useState();
  const [managers, setManagers] = useState();

  useEffectAsync(async () => {
    try {
      const response = await fetch('/api/utils/manager');
      if (response.ok) {
        setManagers(await response.json());
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const handle = useCatch(async () => {
    let url = '/api/credits';
    let bodyContent = `name=${item.name}`;
    if (!id.id) {
      url += '/generate';
      bodyContent += `&quantity=${item.quantity}&assigned=${userId}`;
    } else {
      bodyContent += `&quantity=${add}&credit=${id.id}`;
    }
    const response = await fetch(url, {
      method: id.id ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: bodyContent,
    });

    if (response.ok) {
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  const validate = () => item && item.name && item.quantity;

  return (
    <EditItemView
      endpoint="credits"
      item={item}
      setItem={setItem}
      validate={validate}
      customHandle={handle}
      menu={<RatesMenu />}
      breadcrumbs={['credits']}
      customText={id.id ? 'sharedSave' : 'generate'}
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.name || ''}
              onChange={(event) => setItem({ ...item, name: event.target.value })}
              label={t('sharedName')}
            />
            {!id.id && (
              <FormControl fullWidth>
                <InputLabel>{t('user')}</InputLabel>
                <Select
                  label={t('user')}
                  value={userId || ''}
                  onChange={(e) => dispatch(reportsActions.updateUserId(e.target.value))}
                >
                  {managers && managers.map((user) => (
                    <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              value={id.id ? add : item.quantity || ''}
              type="number"
              onChange={id.id ? (event) => setAdd(event.target.value) : (event) => setItem({ ...item, quantity: event.target.value })}
              inputProps={{ step: '0.1' }}
              label={id.id ? 'Aggiungi' : t('quantity')}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default CreditPage;
