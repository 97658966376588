import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Discount,
  Money,
  QueryStats,
  Subscriptions,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import palette from '../../common/theme/palette';
import { useSubscriptions } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton
    key={link}
    component={Link}
    to={link}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: palette.primary.main, color: 'white' },
      '&.Mui-selected:hover': { backgroundColor: palette.primary.main, color: 'white' },
      borderRadius: 20,
      marginLeft: 1,
      marginRight: 1,
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const RatesMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const subscriptions = useSubscriptions();

  return (
    <List>
      <MenuItem
        title={`${t('prices')}`}
        link="/rates/prices"
        icon={<QueryStats style={{ color: location.pathname.startsWith('/rates/price') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/rates/price')}
      />
      {subscriptions && (
        <MenuItem
          title={`${t('subscriptions')}`}
          link="/rates/subscriptions"
          icon={<Subscriptions style={{ color: location.pathname.startsWith('/rates/subscription') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/rates/subscription')}
        />
      )}
      <MenuItem
        title={t('vouchers')}
        link="/rates/vouchers"
        icon={<Discount style={{ color: location.pathname.startsWith('/rates/voucher') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/rates/voucher')}
      />
      <MenuItem
        title={t('credits')}
        link="/rates/credits"
        icon={<Money style={{ color: location.pathname.startsWith('/rates/credit') || location.pathname.startsWith('/rates/credit') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/rates/credit')}
      />
    </List>
  );
};

export default RatesMenu;
