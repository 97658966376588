import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Grid,
} from '@mui/material';
import InfoCard from '../common/components/InfoCard';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ReportFilter from './components/ReportFilter';
import { formatTime } from '../common/util/formatter';
import { useCatch } from '../reactHelper';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from '../settings/components/SearchHeader';
import { useTranslation } from '../common/components/LocalizationProvider';
import { fixedFee, percentageFees } from '../common/util/stripePercentages';

const PaymentsReportPage = () => {
  const [items, setItems] = useState(null);
  const [grossAmount, setGrossAmount] = useState(null);
  const [netAmount, setNetAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const t = useTranslation();

  const createAmount = (items) => {
    let localAmount = 0;
    let localNetAmount = 0;
    items.forEach((item) => {
      if (item.payment.status === 'succeeded') {
        localAmount += item.payment.amount;
        const stripeFee = ((item.payment.amount * percentageFees) / 100) + (fixedFee * 100);
        localNetAmount += item.payment.amount - stripeFee;
      }
    });
    setGrossAmount(localAmount);
    setNetAmount(localNetAmount);
  };

  const handleSubmit = useCatch(async ({ from, to }) => {
    setLoading(true);
    const query = new URLSearchParams({ from, to });
    try {
      const response = await fetch(`/api/reportsext/payments?${query.toString()}`);
      if (response.ok) {
        const payments = await response.json();
        setItems(payments);
        createAmount(payments);
      } else {
        setLoading(false);
        throw Error(await response.text());
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw Error(error);
    }
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportRoute']}>
      <ReportFilter handleSubmit={handleSubmit} ignoreDevice showOnly />
      <Grid container spacing={2} padding={2} justifyContent="space-between" alignItems="stretch">
        <Grid item xs={6} paddingTop={0}>
          <InfoCard name={`${t('total')} ${t('gross')}`} data={`€ ${Math.round(grossAmount) / 100}`} />
        </Grid>
        <Grid item xs={6} paddingTop={0}>
          <InfoCard name={`${t('total')} ${t('net')}`} data={`€ ${Math.round(netAmount) / 100}`} />
        </Grid>
      </Grid>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      {items && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>userIdentifiers</TableCell>
              <TableCell>Tipo Pagamento</TableCell>
              <TableCell>Ride Info</TableCell>
              <TableCell>Abbonamento Info</TableCell>
              <TableCell>paymentId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item.payment.id}>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatTime(item.payment.created * 1000, 'minutes', false)}</TableCell>
                <TableCell>{`€ ${item.payment.amount / 100}`}</TableCell>
                <TableCell style={item.payment.status === 'succeeded' ? { color: 'green' } : { color: 'red' }}>{item.payment.status}</TableCell>
                <TableCell>
                  <div>{item.user.email}</div>
                  <div>{item.user.phone}</div>
                </TableCell>
                <TableCell>{(item.ride && 'Ride') || (item.wallet && 'Ricarica Wallet') || (item.subscription && 'Abbonamento')}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {item.ride && (
                    <>
                      <div>{`Start: ${formatTime(item.ride.dateStart, 'minutes', false)}`}</div>
                      <div>{`Stop: ${formatTime(item.ride.dateEnd, 'minutes', false)}`}</div>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {item.subscription && 'Sub info'}
                </TableCell>
                <TableCell>{item.payment.idPayment}</TableCell>
              </TableRow>
            )) : (<TableShimmer columns={10} endAction />)}
          </TableBody>
        </Table>
      )}
    </PageLayout>
  );
};

export default PaymentsReportPage;
