import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, FormControl, TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import SelectField from '../../common/components/SelectField';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
}));

const AddCategoryDiscountDialog = ({ open, onResult, categories }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [discount, setDiscount] = useState();
  const [name, setName] = useState();

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogContent className={classes.details}>
        <FormControl>
          <SelectField
            value={name}
            emptyValue={null}
            onChange={(event) => setName(event.target.value)}
            data={categories.map((category) => ({
              id: category.id,
              name: category.name,
            }))}
            label={t('category')}
          />
        </FormControl>
        <FormControl
          fullWidth
        >
          <TextField
            label={`${t('discount')} %`}
            type="number"
            value={discount >= 0 || discount <= 100 ? discount : ''}
            onChange={(e) => setDiscount(e.target.value)}
            inputProps={{ step: '0', min: '0', max: '100' }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!name || !discount}
          onClick={() => onResult({ discount, name })}
        >
          {t('sharedAdd')}
        </Button>
        <Button
          autoFocus
          onClick={() => onResult(null)}
        >
          {t('sharedCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDiscountDialog;
