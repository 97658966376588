import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import RatesMenu from './components/RatesMenu';
import { useEffectAsync } from '../reactHelper';
import EditCategoryDiscountAccordion from './components/EditCategoryDiscountAccordion';
import { removeElementById } from '../common/util/arrayStateUtils';
import { dateToDate } from '../common/util/converter';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const SubscriptionPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const array = [];

  const [item, setItem] = useState();
  const [categories, setCategories] = useState([]);
  const [oldDiscountCategories, setOldDiscountCategories] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [initEnd, setInitEnd] = useState(false);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);

  const validate = () => item && item.price && item.datestart && item.dateend && item.duration && item.discountCategories;

  useEffectAsync(async () => {
    setIsLoading(true);
    if (!id) {
      setItem({ ...item, datestart: dateToDate(from), dateend: dateToDate(to) });
      setIsLoading(false);
    }
    try {
      const response = await fetch('/api/category');
      if (response.ok) {
        setCategories(await response.json());
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  useEffect(() => {
    if (categories.length === 0) return;
    if (id && item) {
      item.discountCategories.forEach((discountCategory) => {
        const category = categories.find((category) => category.name === discountCategory.name);
        discountCategory.name = category.id;
      });
      setIsLoading(false);
      setOldDiscountCategories(item.discountCategories);
    }
  }, [initEnd, categories]);

  const handleSave = async () => {
    try {
      const datestart = moment.utc(item.datestart, moment.HTML5_FMT.DATE).toISOString();
      const dateend = moment.utc(item.dateend, moment.HTML5_FMT.DATE).add(1, 'day').add(-1, 'minute').toISOString();
      const bodySubObj = {
        name: item.name,
        datestart,
        dateend,
        duration: item.duration,
        freeminutes: item.freeminutes,
        price: item.price,
      };

      if (id) bodySubObj.id = id;

      const responseSubscription = await fetch(id ? `/api/subscriptions/${id}` : '/api/subscriptions', {
        method: id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodySubObj),
      });

      if (responseSubscription.ok) {
        const newSubscription = await responseSubscription.json();
        if (!id) {
          item.discountCategories.forEach(async (discountCategory) => fetch('/api/subscriptions/discount', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              subscriptionid: newSubscription.id,
              categoryid: discountCategory.name,
              discount: discountCategory.discount,
            }),
          }));
        } else {
          let copyOldDC = [...oldDiscountCategories];
          item.discountCategories.forEach((discountCategory) => {
            const alreadyExist = copyOldDC.find((oldDiscountCategory) => oldDiscountCategory.id === discountCategory.id);
            const bodyDiscObj = {
              subscriptionid: newSubscription.id,
              categoryid: discountCategory.name,
              discount: discountCategory.discount,
            };

            if (alreadyExist) bodyDiscObj.id = discountCategory.id;

            fetch('/api/subscriptions/discount', {
              method: alreadyExist ? 'PUT' : 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(bodyDiscObj),
            });
            if (alreadyExist) copyOldDC = removeElementById(copyOldDC, alreadyExist.id);
          });
          if (copyOldDC.length > 0) {
            copyOldDC.forEach(async (toRemoveDC) => fetch(`/api/subscriptions/discount/${toRemoveDC.id}`, {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
            }));
          }
        }
      }
      navigate('/rates/subscriptions');
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <EditItemView
      endpoint="subscriptions"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<RatesMenu />}
      breadcrumbs={['subscriptions']}
      customHandle={handleSave}
      customText="sharedSave"
      convertDatesToDate
      setInitEnd={setInitEnd}
    >
      {item && (
        <Accordion defaultExpanded style={{ borderRadius: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.name || ''}
              onChange={(event) => setItem({ ...item, name: event.target.value })}
              label={t('sharedName')}
            />
            <TextField
              value={item.price >= 0 ? item.price / 100 : ''}
              type="number"
              onChange={(event) => setItem({ ...item, price: event.target.value * 100 })}
              inputProps={{ step: '0', min: '0' }}
              label={t('price')}
            />
            <TextField
              label={t('reportFrom')}
              type="date"
              value={item.datestart}
              onChange={(event) => setItem({ ...item, datestart: event.target.value })}
              fullWidth
            />
            <TextField
              label={t('reportTo')}
              type="date"
              value={item.dateend}
              onChange={(event) => setItem({ ...item, dateend: event.target.value })}
              fullWidth
            />
            <TextField
              value={item.duration}
              type="number"
              onChange={(event) => setItem({ ...item, duration: event.target.value })}
              inputProps={{ step: '0', min: '0' }}
              label={`${t('reportDuration')} (GG)`}
            />
            <TextField
              value={item.freeminutes >= 0 ? item.freeminutes : ''}
              type="number"
              onChange={(event) => setItem({ ...item, freeminutes: event.target.value })}
              inputProps={{ step: '0', min: '0' }}
              label={t('freeMinutes')}
            />
          </AccordionDetails>
          {!isLoading && (
            <EditCategoryDiscountAccordion
              discountCategories={item.discountCategories || array}
              setDiscountCategories={(discountCategories) => setItem({ ...item, discountCategories })}
              categories={categories}
            />
          )}
        </Accordion>
      )}
    </EditItemView>
  );
};

export default SubscriptionPage;
