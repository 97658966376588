import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AccountBalance,
  DataUsage,
  MultilineChart,
  QueryStats,
  Whatshot,
} from '@mui/icons-material';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RouteIcon from '@mui/icons-material/Route';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useSharing, useSuperManager } from '../../common/util/permissions';
import palette from '../../common/theme/palette';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton
    key={link}
    component={Link}
    to={link}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: palette.primary.main, color: 'white' },
      '&.Mui-selected:hover': { backgroundColor: palette.primary.main, color: 'white' },
      borderRadius: 20,
      marginLeft: 1,
      marginRight: 1,
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const superManager = useSuperManager();
  const sharing = useSharing();

  return (
    <List>
      <MenuItem
        title="Payments"
        link="/reports/payments"
        icon={<AccountBalance style={{ color: location.pathname === '/reports/payments' ? 'white' : 'gray' }} />}
        selected={location.pathname === '/reports/payments'}
      />
      {sharing && (
        <MenuItem
          title={`${t('statisticsTitle')} ${t('rides')}`}
          link="/reports/rides"
          icon={<QueryStats style={{ color: location.pathname === '/reports/rides' ? 'white' : 'gray' }} />}
          selected={location.pathname === '/reports/rides'}
        />
      )}
      {(superManager && sharing) && (
        <MenuItem
          title={`${t('statisticsTitle')} ${t('advanced')}`}
          link="/reports/advanced"
          icon={<MultilineChart style={{ color: location.pathname === '/reports/advanced' ? 'white' : 'gray' }} />}
          selected={location.pathname === '/reports/advanced'}
        />
      )}
      {sharing && (
        <MenuItem
          title="Stations"
          link="/reports/stations"
          icon={<DataUsage style={{ color: location.pathname === '/reports/stations' ? 'white' : 'gray' }} />}
          selected={location.pathname === '/reports/stations'}
        />
      )}
      {sharing && (
        <MenuItem
          title="HeatMap"
          link="/reports/heatmap"
          icon={<Whatshot style={{ color: location.pathname === '/reports/heatmap' ? 'white' : 'gray' }} />}
          selected={location.pathname === '/reports/heatmap'}
        />
      )}
      {sharing && (
        <MenuItem
          title={t('reportRoute')}
          link="/reports/route"
          icon={<TimelineIcon style={{ color: location.pathname === '/reports/route' ? 'white' : 'gray' }} />}
          selected={location.pathname === '/reports/route'}
        />
      )}
      {sharing && (
        <MenuItem
          title={t('reportEvents')}
          link="/reports/event"
          icon={<NotificationsActiveIcon style={{ color: location.pathname === '/reports/event' ? 'white' : 'gray' }} />}
          selected={location.pathname === '/reports/event'}
        />
      )}
      {sharing && (
        <MenuItem
          title={t('reportReplay')}
          link="/replay"
          icon={<RouteIcon />}
        />
      )}
    </List>
  );
};

export default ReportsMenu;
