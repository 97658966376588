import React, { useState } from 'react';

import {
  Button,
  FormControl,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  IconButton,
} from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';
import SelectField from '../../common/components/SelectField';
import AddCategoryDiscountDialog from './AddCategoryDiscountDialog';
import { removeElementById } from '../../common/util/arrayStateUtils';

const useStyles = makeStyles((theme) => ({
  removeButton: {
    marginRight: theme.spacing(1.5),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  clearButton: {
    width: 30,
    height: 30,
    marginLeft: 'auto',
    marginRight: 0,
  },
}));

const EditCategoryDiscountAccordion = ({ discountCategory, discountCategories, setDiscountCategories, categories }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [addDialogShown, setAddDialogShown] = useState(false);

  const handleAddResult = (definition) => {
    setAddDialogShown(false);
    if (definition) {
      setDiscountCategories([...discountCategories, {
        id: discountCategories.length + 1,
        name: definition.name,
        discount: definition.discount,
      }]);
    }
  };

  return (
    <Accordion defaultExpanded={!!discountCategory} style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('discountCategories')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {discountCategories.map(({
          id, name, discount,
        }, index) => (
          <FormControl key={id} className={classes.details}>
            <IconButton
              size="small"
              className={classes.clearButton}
              onClick={() => setDiscountCategories(removeElementById(discountCategories, id))}
            >
              <HighlightOff />
            </IconButton>
            <SelectField
              value={name}
              emptyValue={null}
              onChange={(e) => {
                const items = [...discountCategories];
                const item = { ...discountCategories[index] };
                item.name = e.target.value;
                items[index] = item;
                setDiscountCategories(items);
              }}
              data={categories.map((category) => ({
                id: category.id,
                name: category.name,
              }))}
              label={t('category')}
            />
            <TextField
              label={`${t('discount')} %`}
              type="number"
              value={discount >= 0 || discount <= 100 ? discount : ''}
              onChange={(e) => {
                const items = [...discountCategories];
                const item = { ...discountCategories[index] };
                item.discount = e.target.value;
                items[index] = item;
                setDiscountCategories(items);
              }}
              inputProps={{ step: '0', min: '0' }}
            />
          </FormControl>
        ))}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setAddDialogShown(true)}
          startIcon={<AddIcon />}
        >
          {t('sharedAdd')}
        </Button>
        <AddCategoryDiscountDialog
          open={addDialogShown}
          onResult={handleAddResult}
          categories={categories}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EditCategoryDiscountAccordion;
