import React from 'react';
import { makeStyles } from '@mui/styles';
import logo from '../resources/images/logoSmallDisplay.png';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '230px',
    maxHeight: '80px',
    width: 'auto',
    height: 'auto',
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  return (<img className={classes.image} color={color} alt="" src={logo} />);
};

export default LogoImage;
