import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
  Card,
  CardMedia,
} from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router-dom';
import { useCatch } from '../reactHelper';
import EditItemView from '../settings/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMenu from './components/PoiMenu';
import EditAttributesAccordion from '../settings/components/EditAttributesAccordion';
import LinkPositionalField from '../common/components/LinkPositionalField';
import PositionalTable from './components/PositionalTable';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const AttractionPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [item, setItem] = useState();
  const [contents, setContents] = useState();
  const [tags, setTags] = useState();

  const validate = () => item && item.name;

  const addContent = (value) => {
    const contTemp = [...contents];
    contTemp.push(value);
    setContents(contTemp);
  };

  const addTag = (value) => {
    const tagTemp = [...tags];
    tagTemp.push(value);
    setTags(tagTemp);
  };

  useEffect(() => {
    setItem({ ...item, attraction: 1 });
  }, []);

  const handleSave = useCatch(async () => {
    let url = '/api/poi';
    if (id) {
      url += `/${id}`;
    }

    const response = await fetch(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      if (!id) {
        navigate(-1);
        return;
      }
      let bodyContentContents = `poiid=${item.id}`;
      contents.forEach((content) => {
        bodyContentContents += `&contents=${content.id}`;
      });
      const resContent = await fetch('/api/poi/contents', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: bodyContentContents,
      });
      if (resContent.ok) {
        let bodyContentTags = `poiid=${item.id}`;
        tags.forEach((tag) => {
          bodyContentTags += `&tags=${tag.id}`;
        });
        const resTag = await fetch('/api/poi/tags', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: bodyContentTags,
        });
        if (resTag.ok) {
          navigate(-1);
        }
      }
    } else {
      throw Error(await response.text());
    }
  });

  const handleFiles = useCatch(async (files) => {
    if (files.length > 0) {
      const response = await fetch(`/api/poi/${item.id}/image`, {
        method: 'POST',
        body: files[0],
      });
      if (response.ok) {
        setItem({ ...item, attributes: { ...item.attributes, image: await response.text() } });
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <EditItemView
      endpoint="poi"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<PoiMenu />}
      breadcrumbs={['managePoi']}
      getContents
      setContents={setContents}
      getTags
      setTags={setTags}
      customHandle={handleSave}
      customText="sharedSave"
    >
      {item && (
        <>
          <Accordion defaultExpanded style={{ borderRadius: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
            </AccordionDetails>
          </Accordion>
          {item.id && (
            <>
              {item.attributes && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('positionImage')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Card elevation={3}>
                      <CardMedia image={`/api/media/public/images/poi/${item.id}/${item.attributes.image}?dummy=${Math.floor(Math.random() * 1000)}`} style={{ height: 200 }} />
                    </Card>
                    <DropzoneArea
                      dropzoneText={t('sharedDropzoneText')}
                      acceptedFiles={['image/*']}
                      filesLimit={1}
                      onChange={handleFiles}
                      showAlerts={false}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('content')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkPositionalField endpoint="/api/contents" add={addContent} label={t('contents')} />
                  <PositionalTable items={contents} setItems={setContents} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    Tags
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkPositionalField endpoint="/api/tags" add={addTag} label="Tags" />
                  <PositionalTable items={tags} setItems={setTags} />
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default AttractionPage;
